import React from 'react'
import { Link } from 'gatsby'
import axios from 'axios'
import ReactDom from 'react-dom';
import Helmet from 'react-helmet'

import ContactForm from '../components/contactform.js'



var title = "How can we help you?"
var subject= "Here is the message: "


// const Calendar = () => (
class Contacts extends React.Component {




	constructor() {
		super()
		this.state = {
			hey: 'Me',
			color: 'black',
			title: "How can we help you?",
			buttonDisabled: false
		}
	}

	render() {
		const style = {
			color: this.state.color
		}


		return (
			<div
			className='transparentContainer'
			>
			<Helmet
			meta={[
				{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
				]}
			/>
			<div className='grid-1-1'>
			<section>
			<ContactForm title={title}/>
			</section>

			<section >
			<h1>Book a call / meeting</h1>
			<div className='whiteCard'>
			<div className="calendly-inline-widget" style={{height: '580px'}}><iframe src="https://zcal.co/i/c9mEbhSA?embed=1&embedType=iframe" loading="lazy" style={{border:'none', minWidth: '320px', minHeight: '544px', height: '100%', width: '100%'}} id="zcal-invite"></iframe>
			</div>
			</div>
			</section>


			</div>

			
			</div> 
			
			)
	}
}
// )

export default Contacts




