import React, {useState} from 'react'
import { Link } from 'gatsby'
import axios from 'axios'
import ReactDom from 'react-dom';



var title = "How can we help you?"
var subject= "Here is the message: "

const Contacts =(props)=>  {

	const [buttonDisabled,setbuttonDisabled]=useState(false)

	const [color,setColor]=useState('black')

	const [showModal, setShowModal]=useState(false)

	const handleSubmit=(event)=> {
		event.preventDefault()
		setbuttonDisabled(true)
		const nameInput = event.target.elements.name
		const emailInput = event.target.elements.email
		const messageInput = event.target.elements.message
		const humanCheck = event.target.elements.noRobotsAllowed
		const subject = props.subject || subject
		if (humanCheck.value != 13) {
			setColor('red')
			setbuttonDisabled(false)
			return alert("Anti robot check not passed, please try again")
		}else{
			setShowModal(true)
		}
		

	setColor('black')
	var data = { name: nameInput.value, email: emailInput.value, message: messageInput.value, subject: subject }
	axios.post('/sendEmail', data)
	.then((response) => {
		nameInput.value = ""
		emailInput.value = ""
		messageInput.value = ""
		humanCheck.value = ""
			// alert("Your message has been sent, thanks!")
		setbuttonDisabled(false)

	})
	.catch(function (error) {
		setbuttonDisabled(false)

	})
	.finally(() => {
		setbuttonDisabled(false)
		;
	})
}


return (
	<div
	className='transparentContainer'
	>
	<h1 className="pageTitle">{props.title || stateObj.title}</h1>
	<form className='whiteCard' onSubmit={handleSubmit}>
	{props.extraText&&<p><span className='highlighter'>{props.extraText}</span></p>}
	<input required={true} style={{
		width: '48%',
		marginRight: '4%',
		border: '1px solid grey'
	}}
	type="text" placeholder="What is your name?" name="name" />

	<input required={true}
	style={{
		width: '48%',
		border: '1px solid grey',
		marginBottom: '1em',
	}}
	type="email" placeholder="Your contact email?" name="email" />
	<textarea required={true} style={{
		border: '1px solid grey'
	}}
	placeholder={props.messagePlaceholder ? props.messagePlaceholder : "Please write your message"}
	name="message"
	/>
	<span style={{color}}>If you are not a robot, how much would be 7 plus 6? </span>
	<input required={true}
	style={{
		width: '2em',
		border: '1px solid grey'
	}}
	type="text" placeholder="" name="noRobotsAllowed" />
	<button type="submit" label="Send" disabled={buttonDisabled}>Send!</button>
	</form>
		{/*modal*/}
	{showModal&&<div className='transparentContainer' style={styles.modal}>
	<section className='whiteCard' style={styles.modalInner}>	
	<span style={styles.closeCross} onClick={()=>setShowModal(false)}>X</span>
	<h1 style={styles.modalInnerText}>Thank you! <br/>We will get in touch promptly...</h1>
	</section>
	</div>}

	</div>
	)
}

export default Contacts

const styles={
	modal:{
		position:'fixed',
		top:'50%',
		left:'50%',
		transform:'translate(-50%,-50%)',
		backgroundColor:'white',
		width:'100%',
		height:'100%',
		// padding:'50px',
		zIndex:1000
	},
	modalInner:{
		// padding:'50px',
		textAlign:'center',
		display:'flex',
		flexDirection:'column',
		height:'100%',
		alignItems:'center',
		justifyContent:'center',
	},
	modalInnerText: {
		// fontSize:'5vw'
		// alignSelf:'center',
	},
	closeCross:{
		position:'absolute',
		top:'0',
		right:'0',
		padding:'30px',
		cursor:'pointer',
		fontSize:'1.5em'
	}
}

